import GATSBY_COMPILED_MDX from "/Users/antonaverin/Dev/Projects/Personal/ARCPanel/ps_ARCPanel/gSite/src/content/_blog/posts/2. free_courses/1. luminosity_masking_basics/en/2020-02-10-luminosity-masking-basics-08-arcenhance/index.mdx";
import React, {useState} from 'react';
import {graphql} from 'gatsby';
import SEO from '@components/seo';
import Layout from '@components/layout/rootLayout';
import MyMDXLayout from '@components/layout/mdxLayout';
import {FormattedMessage} from 'react-intl';
import TOC from '@components/blog/toc';
import SocialButtons from '@components/social';
import {FullScreenPostWrapper, GlobalWidthLimiter, TableOfContentsWrapper, BlogPostBody, StickyWrapper, StickyNav} from '@src/style/mdxStyle';
import ModalSignIn from '@src/components/main/signin/modal_signin.js';
import {ModalSignInContext} from './context';
function Template({pageContext: {locale}, data, children}) {
  const {mdx} = data;
  const {frontmatter, tableOfContents} = mdx;
  const [showSignIn, setShowSignIn] = useState(false);
  let coverImageUrl = '';
  if (frontmatter.cover_image) {
    coverImageUrl = frontmatter.cover_image.publicURL;
  }
  const url = typeof window !== 'undefined' ? window.location.href : '';
  return React.createElement(Layout, {
    locale: locale
  }, React.createElement(MyMDXLayout, null, React.createElement(ModalSignInContext.Provider, {
    value: {
      setShowSignIn: setShowSignIn
    }
  }, React.createElement(SEO, {
    title: frontmatter.title,
    desc: frontmatter.description,
    article: true,
    banner: coverImageUrl,
    publicationDate: frontmatter.date
  }), React.createElement(StickyNav, {
    defaultIndex: 1
  }), React.createElement(FullScreenPostWrapper, null, React.createElement(GlobalWidthLimiter, null, React.createElement(BlogPostBody, null, React.createElement("section", {
    key: mdx.internal.contentFilePath
  }, React.createElement(TableOfContentsWrapper, null, React.createElement(React.Fragment, null, frontmatter.toc && tableOfContents && React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "blog.toc"
  })), React.createElement(StickyWrapper, null, frontmatter.toc && tableOfContents && React.createElement(TOC, {
    items: tableOfContents.items
  }), React.createElement(SocialButtons, {
    frontmatter: frontmatter,
    url: url
  })))), React.createElement(React.Fragment, null, children))))), React.createElement(ModalSignIn, {
    isOpen: showSignIn,
    setIsOpen: setShowSignIn
  }))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query($locale: String, $slug: String!) {
    mdx(fields: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      internal {
        contentFilePath
      }
      tableOfContents(maxDepth: 3)
      frontmatter {
        toc
        date(formatString: "MMMM DD, YYYY")
        title
        description
        hashtags
        cover_image {
          publicURL
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            gatsbyImageData(width: 786, layout: CONSTRAINED)
          }
        }
        images {
          publicURL
        }
      }
    }
  }
`;
